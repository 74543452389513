export function hasClass(el, cn){
    var classes = el.classList;
    for(var j = 0; j < classes.length; j++){
        if(classes[j] == cn){
            return true;
        }
    }
    return false;
}

class Arborescence{
    constructor(){}
    static init(socket) {
        let channel = socket.channel("arborescence:connect", {});
        channel.join()
            .receive("ok", resp => { console.log("Joined successfully", resp) })
            .receive("error", resp => { console.log("Unable to join", resp) });

        channel.onError(e => console.log("something went wrong", e));
        channel.onClose(e => console.log("channel closed", e));

        channel.on("get_child", r => {
            var domId = document.getElementById(r.domId);
            if(domId && r.content){
                domId.style.marginTop="5px";
                domId.style.marginBottom="5px";
                domId.innerHTML = r.content;
                domId.className = "opened";
            }
        });

        channel.on("get_search", r => {
            document.getElementById("modalFiles").querySelector(".modal-content").innerHTML = r.content;
            $('#modalFiles').modal('open');
        });

        document.addEventListener("click",function(e){
            if(hasClass(e.target,"arboItem")){
                var childDom = document.getElementById("child-"+e.target.dataset['id']);
                if(childDom.className!="opened") {
                    channel.push("get_child", {id: e.target.dataset['id'], table: e.target.dataset['ref']})
                }else{
                    childDom.innerHTML="";
                    childDom.className="";
                    childDom.style.marginTop="";
                    childDom.style.marginBottom="";
                }
            }
            if(hasClass(e.target,"arboAddFolder")){
                document.getElementById("folderFormParent").value=e.target.dataset["id"];
            }
            if(hasClass(e.target,"arboEditFolder")){
                document.getElementById("editFolderParent").value=e.target.dataset["parent"];
                document.getElementById("editFolderId").value=e.target.dataset["id"];
                document.getElementById("editFolderTitle").value=e.target.dataset["title"];
            }
            if(hasClass(e.target,"arboAddFile")){
                document.getElementById("folderFormArborescence").value=e.target.dataset["id"];
            }
        })
        var as;
        if(as = document.getElementById("arbo-search")){
            as.addEventListener("keypress",function(e){
                var key = e.which || e.keyCode;
                var search = this.value;
                if (key === 13 && typeof search !== "undefined" && search != "") {
                    //Make an object to send to the channel
                    var obj  = {"search": search, "table": this.dataset['ref']};
                    //Push to the channel for operations
                    channel.push("search",obj);
                }
            })
        }
    }
}

export default Arborescence