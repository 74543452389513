class Search {
    constructor(){}
    static init(socket) {
        let channel = socket.channel("search:hearing", {})
        channel.join()
            .receive("ok", resp => { console.log("Joined successfully", resp) })
            .receive("error", resp => { console.log("Unable to join", resp) })

        channel.onError(e => console.log("something went wrong", e))
        channel.onClose(e => console.log("channel closed", e))

        Search.term_search(channel,"search",{});
        Search.field_sort(channel,"sort",{});
        Search.elem_remove(channel,"search",{search:""},"term_remove");
        Search.elem_remove(channel,"sort_remove",{},"sort_remove");

        channel.on("search", r => {
            if(document.getElementById(r.domId))
                document.getElementById(r.domId).innerHTML = r.content;

        });

        channel.on("sort", r => {
            if(document.getElementById(r.domId)) {
                document.getElementById(r.domId).innerHTML = r.content;
                if(typeof window.sort_obj!=="undefined" && window.sort_obj) {
                    Search.for_sort_change(window.sort_obj['field'],window.sort_obj['value']);
                    window.sort_obj = null;
                }
            }
        })
    }
    /** obj = {search: "string",table: "string"} **/
    static search(ch,ch_name, obj){
        ch.push(ch_name,obj)
    }
    static for_sort_change(field,value){
        var elems = document.querySelectorAll(".search_sort");
        for(var i in elems){
            if(elems.hasOwnProperty(i)) {
                console.log(elems[i]);
                if (elems[i].dataset['field'] == field){
                    elems[i].dataset['value'] = (value == "asc") ? "desc" : "asc";
                }
            }
        }
    }
    static field_sort(ch,ch_name,obj){
        document.addEventListener("click",function(e){
            if(e.target.className == "search_sort"){
                obj["table"] = e.target.dataset['ref'];
                obj["field"] = e.target.dataset['field'];
                obj["value"] = e.target.dataset['value'];

                Search.search(ch, ch_name, obj);
                window.sort_obj = obj
            }
        });
    }
    /** Reloaded element so we have to inspect the element metas **/
    static elem_remove(ch,ch_name, obj,id){
        document.addEventListener("click", function (e) {
            if(e.target.id == id) {
                obj["table"] = e.target.dataset['ref'];
                Search.search(ch, ch_name, obj);
            }
        });
    }
    /** Not reloaded element so we can focus on getting element by id **/
    static term_search(ch,ch_name, obj){
        var el = document.getElementById("search");
        if(el) {
            el.addEventListener("keypress", function (e) {
                var key = e.which || e.keyCode;
                obj["search"] = this.value;
                if (key === 13 && typeof obj["search"] !== "undefined" && obj["search"] != "") {
                    obj["table"] = this.dataset['ref'];
                    Search.search(ch, ch_name, obj);
                }
            });
        }
    }
}

export default Search